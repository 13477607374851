@import "src/client_customizations/scss/client_master.scss";

.Subheader {
    background-color: #F5F5F5;
    a{
        color:$Subheader-link-color;
        font-size: $Header-link-size;
        font-weight: 500;
    }
    a:hover{
        text-decoration: none;
        color: $Subheader-link-hover-color;
    }
    .navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 160px;
			}
		}
		.navbar-collapse {
			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
				}
			}
		}
	}
	.nav-item {
		margin: 5px;
	}
}
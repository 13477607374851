@import "src/client_customizations/scss/client_master.scss";
@import "src/client_customizations/containers/Root/Root.scss";
// Colours
$label-colour: #59BEC9;
$toggle-colour: #73767B;
$white: #fff;

// Styles
.toggle-row {
  width: auto;  
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;

  @media screen and (max-width: 575px) {    
    width: 100%;  
    margin-left: 0;

    .toggle-text {
      margin: 0 auto 0 0;
    }
  }
}
.toggle-switch {
  position: relative;
  width: 50px;
  height: 27px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: center;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 17.551px;
    margin: 0;    
    height: 27px;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 13px;
      font-weight:500;
      color: white;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      background-color: $label-colour;
      color: $white;
      text-align: left;
      padding-left: 12px;
    }
  }
  &-inner:after {
    content: attr(data-no);
    background-color: $toggle-colour;
    color: $white;
    text-align: right;
    padding-right:12px;
  }
  &-switch {
    display: block;
    width: 20px;
    height: 21px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 0 solid $label-colour;
    border-radius: 17.551px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
    .toggle-switch-switch {
      height: 21px;
    }
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
    .toggle-switch-switch {
      height: 21px;
    }
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
    .toggle-switch-switch {
      height: 21px;
    }
  }
}

.toggle-image {
    padding-left:0px;
    padding-right:0px;
    margin-right:6px;
    display:flex;
    align-items: center;
    img {
        width: 25px;
    }
}

.toggle-text {
  font-family: $font_family_1;;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #2A2A2A;
  margin: 0 8px 0 0;
}

.toggle-text-disabled {
  color: #747676;
}

.col-9 {
    padding-left: 4px;
    padding-right: 0px;
}

.row {
    margin-bottom: 4px;
    margin-top: 4px;
}

.toggle-switch {
    padding-left: 0px;
    padding-right: 0px;
}
.toggle-switch-darkBlue {
  .toggle-switch-inner:before {
    background-color: #1E3575;
  }  
}
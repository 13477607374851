@import "src/client_customizations/scss/client_master.scss"; 

.EVReviews {
  text-align: left;
  padding: 0;
}

.EVReviews .h3 {
  text-align: left;
}

.EVReviews p {
  margin-top: 10px;
}

.VideoReview {
  margin-top: 10px;
  margin-bottom: 30px;
  p {
    text-align: center;
  }
}


@import "src/client_customizations/scss/client_master.scss";

.EVsSort {
	margin-bottom: 15px;
	label {
		font-weight: $EVsSort-label-font-weight;
		font-size: $EVsSort-label-font-size;
		color: $EVsSort-label-color;
		letter-spacing:$EVsSort-label-letter-spacing;
		line-height: $EVsSort-label-line-height;
	}
	select {
		// background: $EVsSort-select-background;
		border: $EVsSort-select-border;
		// border-radius:$EVsSort-select-border-radius;
		font-size: $EVsSort-select-font-size;
		color: $EVsSort-select-color;
		letter-spacing: $EVsSort-select-letter-spacing;
	}
}

.evsToggle {
	margin-bottom: 1rem;
	text-align: center;
}

.rangeToggle {
	display: flex;
	justify-content: center;

	button {
		width: 252px;
		height: 36px;
		font-size: 16px;

		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.renderToolTip {	
	width: 540px;
	height: 158px;
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;

	background: #FFFFFF;
	border: 1px solid $dteBlue;	
	box-sizing: border-box;
}

.renderToolTip:after,
.renderToolTip:before {
    content: '';
    display: block;
    position: absolute;
    left: 43.5%;
    width: 0;
    height: 0;
    margin: 146px 0 0 0;
    transform: rotate(90deg);
    border-style: solid;
}

.renderToolTip:after {
    top: 10px;
    border-color: transparent transparent transparent white;
    border-width: 10px;
    margin-left: 1px;
}

.renderToolTip:before {
    top: 10px;
    border-color: transparent transparent transparent $dteBlue;
    border-width: 11px;
}

.tooltipEvName {
	color:#0072CE;
	border-bottom: solid 5px #d9d9d9;
	font-size:18px;
}

.tooltipEvName:hover{
	text-decoration:none;
}

.tooltipTextContainer {
	display:flex;
	flex-direction: column;
}

.tooltipDescription {
	display:flex;
	flex-direction: column;
	max-width: 300px;

	h5 {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 18px;
		color: $darkGray;
	}
	p {
		font-size: 16px;
		margin-bottom: 0px;
		color: $darkGray;
	}
}

.lead {
	padding-top:12px;
}

.evsToggle {
	padding-bottom: 20px;
}

.mobileText {
	margin: 15px;
}

.evs-toggle-image {
	padding-right: 8px;
}

.toggle-btn{
	padding: 10px 40px;
	font-size: 18px;
	border-radius: 0;
	border: 0;
	color: $dteBlue;
	font-weight: 500;
	background-color: $white;
	border: 1px solid $dteBlue;
	vertical-align: middle;
	margin-left: 0px;
	margin-right:0px;	
	outline: none !important;
	.toggle-image-hover {
		display:none;
	}

	&:hover {
		border-radius: 0;
		color: $white;
		font-weight: 500;
		text-decoration: none;
		background-color: $dteBlue;
		.toggle-image-passive {
			display:none;
		}
		.toggle-image-hover {
			display:inline;
		}
	}
}

.toggle-btn-active{
	padding: 10px 40px;
	font-size: 18px;
	border-radius: 0;
	border: 0;
	color: $white;
	font-weight: 500;
	background-color: $dteBlue;
	border: 1px solid $dteBlue;
	vertical-align: middle;
	margin-right: 0px;
	margin-left: 0px;
	outline: none !important;
		.toggle-image-passive {
			display:none
		}
}

@media (min-width: 576px) {
	.EVsSort {
		float: right;
	}
}

.cost-vs-range-graph {
	.evs-toggle-image {
		padding-right: 8px;
	}
	.toggle-btn{
		padding: 10px 40px;
		font-size: 18px;
		border-radius: 0;
		border: 0;
		color: $dteBlue;
		font-weight: 500;
		background-color: $white;
		border: 1px solid $dteBlue;
		vertical-align: middle;
		margin-left: 0px;
		margin-right:0px;
		.toggle-image-hover {
			display:none;
		}
		&:hover {
			border-radius: 0;
			color: $white;
			font-weight: 500;
			text-decoration: none;
			background-color: $dteBlue;
			.toggle-image-passive {
				display:none;
			}
			.toggle-image-hover {
				display:inline;
			}
		}
	}
	
	.toggle-btn-active{
		padding: 10px 40px;
		font-size: 18px;
		border-radius: 0;
		border: 0;
		color: $white;
		font-weight: 500;
		background-color: $dteBlue;
		border: 1px solid $dteBlue;
		vertical-align: middle;
		margin-right: 0px;
		margin-left: 0px;
		.toggle-image-passive {
			display:none
		}
	}
}

.cost-vs-range-disclaimer {
	font-weight: normal;
	font-size: 10px;
	color: $darkGray;
	text-align: center;
}

.secondary-btn {	
	width: 139px;
	height: 42px;

	display: flex;
	justify-content: center;
	align-items: center;

	background: $secondaryBlue;
	border-radius: 0;
	border: 0;

	font-size: 14px;
	color: $white;
	font-weight: 500;
}

.input-label {
	font-weight: $EVsSort-label-font-weight;
	font-size: $EVsSort-label-font-size;
	color: $EVsSort-label-color;
	letter-spacing:$EVsSort-label-letter-spacing;
	line-height: $EVsSort-label-line-height;	

	margin: 0;
}

.cvr-select {
	width: 266px;
	height: 33px;

	padding-right: 32px;
	margin-left: auto;

	text-overflow: ellipsis;
	font-size: 14px;

	outline: none;
}

.cvr-modal {
	width: 100vw;
	height: 100vh;

	position: fixed;
	top: 0;
	left: 0;

	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 18px;

	background-color: $white;

	h5 {
		font-weight: 600;
		font-size: 18px;
		line-height: 25px;

		margin: 0;
	}

	.modal-close-btn {
		background-color: transparent;
		border: none;
	}
}

.apply-btn-col {
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.h40 {
	height: 40px;
}

.cvr-evcard {
	width: 339px;
	height: 202px;

	margin: 0 0 15px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 18px 12px 14px;

	background: $white;
	border: 1px solid #DBDBDB;
	box-sizing: border-box;
	
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;

	color: black;

	p {
		margin: 0;
	}

	div {
		width: 100%;
		min-height: 34px;
		margin: 0;

		display: flex;
		justify-content: space-between;
		align-items: center;

		padding: 0 7px;
	}

	img {
		width: 101px;
		height: 57px;

		margin: 0 20px 0 0;
	}

	.gray-block {		
		background: #E5E5E5;
		border-radius: 5px;
	}
}

.link-text {
	max-width: 168px;

	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;

	color: $secondaryBlue;
}

.cvr-list-container{
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

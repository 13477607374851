@import "src/client_customizations/scss/client_master.scss";

.VehicleImage {
    .loading-overlay {
        width: 320px;
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .img-loading {
        display: none;
    }
}